import React, { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

export const Contact = () => {
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const messageRef = useRef(null);
  const [emailError, setEmailError] = useState("");
  const [nameError, setNameError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [recaptchaError, setRecaptchaError] = useState("");
  const [captchaValue, setCaptchaValue] = useState(null);
  const [sending, setSending] = useState(false);
  const [success, setSuccess] = useState(false);

  const validateEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };
  const handleMessage = async () => {
    setEmailError("");
    setMessageError("");
    setRecaptchaError("");
    setNameError("");
    const email = emailRef.current.value;
    const name = nameRef.current.value;
    const message = messageRef.current.value;
    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address.");
    }
    if (name === "") {
      setNameError("Please enter your name.");
    }
    if (message === "") {
      setMessageError("Please enter your message.");
    }
    if (!captchaValue) {
      setRecaptchaError("Error verifying reCaptcha.");
    }
    const headers = {
      "Content-Type": "application/json",
    };
    headers["x-api-key"] = process.env.API_KEY;
    if (validateEmail(email) && name !== "" && message !== "" && captchaValue) {
      setSending(true);
      const response = await fetch(`${process.env.API_URL}/register/message`, {
        method: "post",
        headers: headers,
        body: JSON.stringify({
          name: name,
          email: email,
          message: message,
          captchaValue: captchaValue,
        }),
      });
      if (response.status === 200) {
        const data = await response.json();
        if (data.success === true) {
          setSuccess(true);
        } else {
          setSuccess(false);
          setRecaptchaError("Message failed to send.");
          setSending(false);
        }
      }
    }
  };

  useEffect(() => {
    const updatePageTitle = () => {
      document.title = "CONTACT US | Cricket8";
    };
    updatePageTitle();
    return () => {};
  }, []);

  function onChange(value) {
    setCaptchaValue(value);
  }

  return (
    <div
      id="content"
      className="min-h-[75vh] mt-[0px] md:mt-[20px] p-2 content-t"
    >
      <h1
        className="text-[30px] md:mt-[10px] md:text-[55px] md:mb-[10px]"
        style={{ color: "#d2ff00" }}
      >
        CONTACT US
      </h1>
      <div className="md:hidden lg:hidden mt-[10px] w-full md:flex md:flex-col pb-[40px] font-chakra font-[#fff] text-[16px] bg-[#452f5b] p-[20px]">
        <div className="flex flex-row justify-start items-center">
          <div className="w-[40px] h-[40px] flex justify-center items-center bg-[#d2ff00] text-main mr-[15px]">
            <svg
              width="1.35em"
              height="1.35em"
              viewBox="0 0 22 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.0003 0.25C8.57113 0.25 6.18302 0.427728 3.84836 0.771003C1.87004 1.06188 0.5 2.79455 0.5 4.74064V10.7593C0.5 12.7054 1.87004 14.4381 3.84836 14.729C4.72491 14.8579 5.60901 14.9634 6.5 15.045V19C6.5 19.3033 6.68273 19.5768 6.96299 19.6929C7.24324 19.809 7.56583 19.7448 7.78033 19.5303L11.9638 15.3468C12.0318 15.2788 12.1266 15.2374 12.2302 15.2348C14.2361 15.1851 16.2123 15.0142 18.1516 14.7291C20.1299 14.4382 21.5 12.7056 21.5 10.7595V4.74056C21.5 2.79445 20.1299 1.06177 18.1516 0.770912C15.8171 0.427696 13.4292 0.25 11.0003 0.25ZM7.25 6.625C6.62868 6.625 6.125 7.12868 6.125 7.75C6.125 8.37132 6.62868 8.875 7.25 8.875C7.87132 8.875 8.375 8.37132 8.375 7.75C8.375 7.12868 7.87132 6.625 7.25 6.625ZM9.875 7.75C9.875 7.12868 10.3787 6.625 11 6.625C11.6213 6.625 12.125 7.12868 12.125 7.75C12.125 8.37132 11.6213 8.875 11 8.875C10.3787 8.875 9.875 8.37132 9.875 7.75ZM14.75 6.625C14.1287 6.625 13.625 7.12868 13.625 7.75C13.625 8.37132 14.1287 8.875 14.75 8.875C15.3713 8.875 15.875 8.37132 15.875 7.75C15.875 7.12868 15.3713 6.625 14.75 6.625Z"
                fill="#18062F"
              />
            </svg>
          </div>
          <p className="font-anton font-[400] text-[16px] leading-[20px]">
            GET IN TOUCH
          </p>
        </div>

        <div className="mt-[20px] w-full md:flex md:flex-col md:justify-center md:items-center md:mt-[0px] flex-grow">
          <div className="md:flex md:flex-col md:justify-center items-center md:w-full md:p-2">
            <div className="w-full">
              <label
                className="text-[14px] font-[400] leading-[20px] font-chakra normal-case"
                htmlFor="name"
              >
                Name:
              </label>
              <input
                ref={nameRef}
                className="enteremail"
                type="text"
                placeholder="your name"
                id="name"
              />
              {nameError && (
                <div
                  style={{
                    color: "#f73261",
                    paddingTop: "5px",
                    paddingLeft: "20px",
                  }}
                >
                  {nameError}
                </div>
              )}
            </div>
            <div className="w-full">
              <label
                className="text-[14px] font-[400] leading-[20px] font-chakra normal-case"
                htmlFor="email"
              >
                Email:
              </label>
              <input
                ref={emailRef}
                className="enteremail"
                type="email"
                placeholder="enter your email"
                id="id"
              />
              {emailError && (
                <div
                  style={{
                    color: "#f73261",
                    paddingTop: "5px",
                    paddingLeft: "20px",
                  }}
                >
                  {emailError}
                </div>
              )}
            </div>

            <div className="w-full">
              <label
                className="text-[14px] font-[400] leading-[20px] font-chakra normal-case"
                htmlFor="message"
              >
                Message:
              </label>
              <textarea
                ref={messageRef}
                className="enteremail !mt-[5px] !w-[100%] h-[88px] md:h-[60px]"
                placeholder="your message"
                id="message"
              />
              {messageError && (
                <div
                  style={{
                    color: "#f73261",
                    paddingTop: "5px",
                    paddingLeft: "20px",
                  }}
                >
                  {messageError}
                </div>
              )}
            </div>
          </div>
          <div className="md:w-full md:flex md:flex-col">
            <div className="md:h-[50px]">
              <ReCAPTCHA
                sitekey="6LcNubcpAAAAADIenWKHvGywBH0hNAL55T9yrqL8"
                className="h-[50px] mt-[20px] mb-[45px] md:mt-0 md:mb-0"
                onChange={onChange}
              />
              {recaptchaError && (
                <div
                  style={{
                    color: "#f73261",
                    paddingTop: "5px",
                    paddingLeft: "20px",
                  }}
                >
                  {recaptchaError}
                </div>
              )}
            </div>

            <div className="md:mt-4">
              {success ? (
                <div className="text-[#32f78c] font-anton text-[24px] pt-[20px]">
                  Message sent!
                </div>
              ) : null}
              {!sending ? (
                <div
                  className="mt-[20px] h-[52px] w-[110px] bg-[#d2ff00] font-anton text-[20px] text-[#1b062f] px-[20px] py-[16px] flex cursor-pointer"
                  onClick={handleMessage}
                >
                  <div>SEND</div>
                  <div className="ml-[15px]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="1em"
                      height="1em"
                      viewBox="-5 -5 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="m10.586 5.657l-3.95-3.95A1 1 0 0 1 8.05.293l5.657 5.657a.997.997 0 0 1 0 1.414L8.05 13.021a1 1 0 1 1-1.414-1.414l3.95-3.95H1a1 1 0 1 1 0-2z"
                      />
                    </svg>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="lg:flex lg:flex-row lg:mt-[20px]">
        <div className="md:mr-4">
          <div className="lg:flex lg:flex-row gap-4">
            <div className="mt-[10px] w-full md:h-[80px] flex flex-row justify-between items-center font-chakra font-[#fff] text-[16px] bg-[#452f5b] p-[20px] md:flex">
              <div className="flex flex-row justify-center items-center">
                <div className="w-[40px] h-[40px] flex justify-center items-center bg-[#d2ff00] text-main mr-[15px]">
                  <svg
                    width="1.35em"
                    height="1.35em"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0.5 3.5C0.5 1.84315 1.84315 0.5 3.5 0.5H4.87163C5.732 0.5 6.48197 1.08556 6.69064 1.92025L7.79644 6.34343C7.97941 7.0753 7.70594 7.84555 7.10242 8.29818L5.8088 9.2684C5.67447 9.36915 5.64527 9.51668 5.683 9.61969C6.81851 12.7195 9.28051 15.1815 12.3803 16.317C12.4833 16.3547 12.6309 16.3255 12.7316 16.1912L13.7018 14.8976C14.1545 14.2941 14.9247 14.0206 15.6566 14.2036L20.0798 15.3094C20.9144 15.518 21.5 16.268 21.5 17.1284V18.5C21.5 20.1569 20.1569 21.5 18.5 21.5H16.25C7.55151 21.5 0.5 14.4485 0.5 5.75V3.5Z"
                      fill="#18062F"
                    />
                  </svg>
                </div>
                <p className="font-anton font-[400] text-[16px] leading-[20px]">
                  Phone
                </p>
              </div>
              <p className="text-[14px] font-[400] leading-[20px] font-chakra">
                +44 (0) 208 135 9165
              </p>
            </div>

            <div className="mt-[10px] w-full md:h-[80px] flex flex-row justify-between items-center font-chakra font-[#fff] text-[16px] bg-[#452f5b] p-[20px] md:flex">
              <div className="flex flex-row justify-center items-center">
                <div className="w-[40px] h-[40px] flex justify-center items-center bg-[#d2ff00] text-main mr-[15px]">
                  <svg
                    width="1.35em"
                    height="1.35em"
                    viewBox="0 0 21 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.45039 0.600098C1.18014 0.600098 0.150391 1.62984 0.150391 2.9001V4.23579L9.85752 9.08936C10.2622 9.29171 10.7386 9.29171 11.1433 9.08936L20.8504 4.23579V2.9001C20.8504 1.62984 19.8206 0.600098 18.5504 0.600098H2.45039Z"
                      fill="#18062F"
                    />
                    <path
                      d="M20.8504 6.1644L11.9147 10.6322C11.0244 11.0774 9.97641 11.0774 9.08608 10.6322L0.150391 6.1644V12.1001C0.150391 13.3704 1.18014 14.4001 2.45039 14.4001H18.5504C19.8206 14.4001 20.8504 13.3704 20.8504 12.1001V6.1644Z"
                      fill="#18062F"
                    />
                  </svg>
                </div>
                <p className="font-anton font-[400] text-[16px] leading-[20px]">
                  Email
                </p>
              </div>
              <p className="text-[13px] font-[400] leading-[20px] font-chakra normal-case">
                support@cricket8.com
              </p>
            </div>
          </div>

          <div className="md:flex w-full md:flex-row md:gap-4">
            <div className="mt-[10px] w-full h-[300px] flex flex-col font-chakra font-[#fff] text-[16px] bg-[#452f5b] p-[20px] md:flex">
              <div className="flex flex-row justify-start items-center mb-[10px]">
                <div className="w-[40px] h-[40px] flex justify-center items-center bg-[#d2ff00] text-main mr-[15px]">
                  <img src="../img/public/gb.png" />
                </div>
                <p className="font-anton font-[400] text-[16px] leading-[20px]">
                  UK OFFICE
                </p>
              </div>
              <div>
                <p className="text-[14px] font-[400] leading-[20px] font-chakra mb-[5px] normal-case">
                  Pukka Entertainment Limited (trading as Cricket8)
                  <span className="mt-[5px] block normal-case">
                    5th Floor, 167-169 Great Portland Street, London, W1W 5PF
                  </span>
                  <span className="mt-[5px] block normal-case">
                    U.K. Registered Company Number: 14138673
                  </span>
                </p>
              </div>
            </div>

            <div className="mt-[10px] w-full h-[300px] flex flex-col font-chakra font-[#fff] text-[16px] bg-[#452f5b] p-[20px] md:flex">
              <div className="flex flex-row justify-start items-center mb-[10px]">
                <div className="w-[40px] h-[40px] flex justify-center items-center bg-[#d2ff00] text-main mr-[15px]">
                  <img src="../img/public/in.png" />
                </div>
                <p className="font-anton font-[400] text-[16px] leading-[20px]">
                  INDIA OFFICE
                </p>
              </div>
              <div>
                <p className="text-[14px] font-[400] leading-[20px] font-chakra mb-[5px] normal-case">
                  Pukka Entertainment Private Limited
                  <span className="mt-[5px] block normal-case">
                    We Work Vaswani Chambers, 2nd Floor, 264-265, Dr Annie
                    Beasant Road, Municipal Colony, Worli Shivaji Nagar, Mumbai,
                    Maharashtra, 400025/30
                  </span>
                  <span className="mt-[5px] block normal-case">
                    India Company Number: U63910MH2024FTC428666
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden mt-[10px] w-full md:flex md:flex-col pb-[40px] font-chakra font-[#fff] text-[16px] bg-[#452f5b] p-[20px]">
          <div className="flex flex-row justify-start items-center">
            <div className="w-[40px] h-[40px] flex justify-center items-center bg-[#d2ff00] text-main mr-[15px]">
              <svg
                width="1.35em"
                height="1.35em"
                viewBox="0 0 22 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11.0003 0.25C8.57113 0.25 6.18302 0.427728 3.84836 0.771003C1.87004 1.06188 0.5 2.79455 0.5 4.74064V10.7593C0.5 12.7054 1.87004 14.4381 3.84836 14.729C4.72491 14.8579 5.60901 14.9634 6.5 15.045V19C6.5 19.3033 6.68273 19.5768 6.96299 19.6929C7.24324 19.809 7.56583 19.7448 7.78033 19.5303L11.9638 15.3468C12.0318 15.2788 12.1266 15.2374 12.2302 15.2348C14.2361 15.1851 16.2123 15.0142 18.1516 14.7291C20.1299 14.4382 21.5 12.7056 21.5 10.7595V4.74056C21.5 2.79445 20.1299 1.06177 18.1516 0.770912C15.8171 0.427696 13.4292 0.25 11.0003 0.25ZM7.25 6.625C6.62868 6.625 6.125 7.12868 6.125 7.75C6.125 8.37132 6.62868 8.875 7.25 8.875C7.87132 8.875 8.375 8.37132 8.375 7.75C8.375 7.12868 7.87132 6.625 7.25 6.625ZM9.875 7.75C9.875 7.12868 10.3787 6.625 11 6.625C11.6213 6.625 12.125 7.12868 12.125 7.75C12.125 8.37132 11.6213 8.875 11 8.875C10.3787 8.875 9.875 8.37132 9.875 7.75ZM14.75 6.625C14.1287 6.625 13.625 7.12868 13.625 7.75C13.625 8.37132 14.1287 8.875 14.75 8.875C15.3713 8.875 15.875 8.37132 15.875 7.75C15.875 7.12868 15.3713 6.625 14.75 6.625Z"
                  fill="#18062F"
                />
              </svg>
            </div>
            <p className="font-anton font-[400] text-[16px] leading-[20px]">
              GET IN TOUCH
            </p>
          </div>

          <div className="mt-[20px] w-full md:flex md:flex-col md:justify-center md:items-center md:mt-[0px] flex-grow">
            <div className="md:flex md:flex-col md:justify-center items-center md:w-full md:p-2">
              <div className="w-full">
                <label
                  className="text-[14px] font-[400] leading-[20px] font-chakra normal-case"
                  htmlFor="name"
                >
                  Name:
                </label>
                <input
                  ref={nameRef}
                  className="enteremail"
                  type="text"
                  placeholder="your name"
                  id="name"
                />
                {nameError && (
                  <div
                    style={{
                      color: "#f73261",
                      paddingTop: "5px",
                      paddingLeft: "20px",
                    }}
                  >
                    {nameError}
                  </div>
                )}
              </div>
              <div className="w-full">
                <label
                  className="text-[14px] font-[400] leading-[20px] font-chakra normal-case"
                  htmlFor="email"
                >
                  Email:
                </label>
                <input
                  ref={emailRef}
                  className="enteremail"
                  type="email"
                  placeholder="enter your email"
                  id="id"
                />
                {emailError && (
                  <div
                    style={{
                      color: "#f73261",
                      paddingTop: "5px",
                      paddingLeft: "20px",
                    }}
                  >
                    {emailError}
                  </div>
                )}
              </div>

              <div className="w-full">
                <label
                  className="text-[14px] font-[400] leading-[20px] font-chakra normal-case"
                  htmlFor="message"
                >
                  Message:
                </label>
                <textarea
                  ref={messageRef}
                  className="enteremail !mt-[5px] !w-[100%] h-[88px] md:h-[60px]"
                  placeholder="your message"
                  id="message"
                />
                {messageError && (
                  <div
                    style={{
                      color: "#f73261",
                      paddingTop: "5px",
                      paddingLeft: "20px",
                    }}
                  >
                    {messageError}
                  </div>
                )}
              </div>
            </div>
            <div className="md:w-full md:flex md:p-2 md:flex-col">
              <div className="md:h-[50px]">
                <ReCAPTCHA
                  sitekey="6LcNubcpAAAAADIenWKHvGywBH0hNAL55T9yrqL8"
                  className="h-[50px] mt-[20px] mb-[45px] md:mt-0 md:mb-0"
                  onChange={onChange}
                />
                {recaptchaError && (
                  <div
                    style={{
                      color: "#f73261",
                      paddingTop: "5px",
                      paddingLeft: "20px",
                    }}
                  >
                    {recaptchaError}
                  </div>
                )}
              </div>

              <div className="md:mt-4">
                {success ? (
                  <div className="text-[#32f78c] font-anton text-[24px] pt-[20px]">
                    Message sent!
                  </div>
                ) : null}
                {!sending ? (
                  <div
                    className="mt-[20px] h-[52px] w-[110px] bg-[#d2ff00] font-anton text-[20px] text-[#1b062f] px-[20px] py-[16px] flex cursor-pointer"
                    onClick={handleMessage}
                  >
                    <div>SEND</div>
                    <div className="ml-[15px]">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1em"
                        height="1em"
                        viewBox="-5 -5 24 24"
                      >
                        <path
                          fill="currentColor"
                          d="m10.586 5.657l-3.95-3.95A1 1 0 0 1 8.05.293l5.657 5.657a.997.997 0 0 1 0 1.414L8.05 13.021a1 1 0 1 1-1.414-1.414l3.95-3.95H1a1 1 0 1 1 0-2z"
                        />
                      </svg>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Contact;
